<template>
    <div class="box">
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="introduction">
            <video v-if="data.moocChapterList.length>0" :src="data.moocChapterList[playIndex].videoUrl" controls ref="video"></video>
            <div class="right">
                <div class="title">{{ data.curriculumName }}</div>
                <div class="teacher">授课老师：{{ data.teacherName }}</div>
                <div class="time">
                    <img src="@/assets/images/Frame-37.png" alt="" />
                    {{ data.openClassTime }}
                </div>
                <div class="reading">
                    <img src="@/assets/images/Frame-38.png" alt="" />
                    {{ data.playerCount || 0 }}
                </div>
                <div class="line"></div>
                <div class="list">
                    <div class="item" :class="{ active: playIndex == index }" v-for="(item, index) in data.moocChapterList" :key="index">
                        <img src="@/assets/images/Frame-41.png" alt="" />
                        <p>{{ item.chapter || '' }}</p>
                        <span v-if="playIndex === index" class="isPlay">当前播放</span>
                        <span v-else class="noPlay" @click="playIndex = index">播放</span>
                    </div>
                </div>
                <div class="button">
                    <div @click="collection">
                        <img :src="!data.colletionId ? require('@/assets/images/Frame-39.png') : require('@/assets/images/Vector.png')" alt="" />
                        <span>{{ data.colletionId ? '已' : '' }}收藏</span>
                    </div>
                    <div @click="praise">
                        <img :src="!data.praiseId ? require('@/assets/images/Frame-40.png') : require('@/assets/images/Frame-50.png')" alt="" />
                        <span>{{ data.praiseId ? '已' : '' }}点赞</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-title">讲师简介</div>
        <div class="w-content" v-html="data.teacherDesc ? data.teacherDesc.replace(/<img/g, '<img style=width:100%;height:auto; ') : ''"></div>
        <div class="w-title">课程概述</div>
        <div class="w-content">{{ data.curriculumDesc }}</div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '慕课学习',
                    to: { path: '/moocLearning' },
                },
                {
                    title: '慕课详情',
                    to: { path: '/moocLearningDetail' },
                },
            ],
            playIndex: 0,
            id: 0,
            data: {},
        };
    },
    watch: {
        playIndex() {
            this.$refs.video.play();
        },
    },
    created() {
        this.id = this.$route.query.id;
        this.getDetail();
    },
    methods: {
        getDetail() {
            this.$hao.getMooclearnDetail(this.id).then((res) => {
                this.data = res.data;
            });
        },
        // 收藏
        collection() {
            if (this.data.colletionId === null) {
                this.$hao.addPraiseCollection({ relatedId: this.data.id, type: 9 }).then((res) => {
                    this.$message.success('收藏成功');
                    this.data.colletionId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.data.colletionId).then(() => {
                    this.$message.success('取消收藏成功');
                    this.data.colletionId = null;
                });
            }
        },
        // 点赞
        praise() {
            if (this.data.praiseId === null) {
                this.$hao.addPraiseCollection({ relatedId: this.data.id, type: 10 }).then((res) => {
                    this.$message.success('点赞成功');
                    this.data.praiseId = res.data.id;
                });
            } else {
                this.$hao.delPraiseCollection(this.data.praiseId).then(() => {
                    this.$message.success('取消点赞成功');
                    this.data.praiseId = null;
                });
            }
        },
    },
};
</script>

<style lang="less" scoped>
.introduction {
    display: flex;
    video {
        width: 900px;
        height: 506.25px;
    }
    .right {
        border: 1px solid #ebebeb;
        //margin-left: 20px;
        overflow: hidden;
        height: 506.25px;
        flex: 1;
        padding: 12px 16px;
        background: #ffffff;
        display: flex;
        flex-direction: column;
        .title {
            font-size: 20px;
            font-weight: bold;
            color: #333333;
        }
        .teacher {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
        }
        .time {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
            display: flex;
            align-items: center;

            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
        .reading {
            font-size: 14px;
            color: #999999;
            margin-top: 10px;
            display: flex;
            align-items: center;
            img {
                width: 14px;
                height: 14px;
                margin-right: 5px;
            }
        }
        .line {
            width: 100%;
            height: 1px;
            background: #e5e5e5;
            margin-top: 20px;
        }
        .list {
            flex: 1;
            overflow-y: scroll;
            display: flex;
            align-items: center;
            flex-direction: column;
            width: 100%;
            margin: 10px 0;
            .item {
                width: 100%;
                margin-top: 10px;
                display: flex;
                align-items: center;
                background: #f6f6f6;
                padding: 10px 12px;
                min-height: 45px;
                img {
                    width: 20px;
                    height: 20px;
                }
                p {
                    flex: 1;
                }
                .isPlay {
                    font-size: 12px;
                    color: #eda200;
                }
                .noPlay {
                    width: 48px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    background: #eda200;
                    border-radius: 2px;
                    font-size: 12px;
                    color: #ffffff;
                }
            }
            .active {
                background: rgba(237, 162, 0, 0.1);
            }
        }
        .button {
            column-count: 2;
            column-gap: 10px;
            div:nth-child(1) {
                border: 1px solid #f98240;
                color: #f98240;
            }
            div:nth-child(2) {
                border: 1px solid #f33f3e;
                color: #f33f3e;
            }
            div {
                width: 100%;
                height: 40px;
                align-items: center;
                justify-content: center;
                border-radius: 2px;
                display: flex;
                img {
                    width: 18px;
                    height: 18px;
                    margin-right: 5px;
                }
            }
        }
    }
}
.w-title {
    margin-top: 24px;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
}
.w-content {
    font-size: 16px;
    margin-top: 12px;
    color: #666666;
}
</style>
